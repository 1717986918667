import { calculate } from './calculate';

const MAX_SIDE = 50;
const MAX_OTHER_SIDE = 45;
const MAX_SUM = 120;
const MAX_WEIGHT = 10000;

// TODO: 購物車 totalPrice 檢查要替換成該 fn 統一業務邏輯
/**
 * 業務邏輯: 判定至小數點第二位
 *  - 最長邊不超過 50
 *  - 任兩邊不超過 45
 *  - 長+寬+高 < 120
 *  - 重量不超過 10kg (API 單位為公克)
 * @param {number} length 長
 * @param {number} width 寬
 * @param {number} height 高
 * @param {number} weight 重量
 * @returns {boolean}
 */
export const validateLimitVolume = (length, width, height, weight) => {
  const sides = [length, width, height];
  const maxSide = Math.max(...sides);
  const sidesOverLimit = sides.filter((side) => side > MAX_OTHER_SIDE);
  const sum = calculate(length + height + width);

  if (weight >= MAX_WEIGHT) {
    return false;
  }

  if (maxSide > MAX_SIDE) {
    return false;
  }

  if (sidesOverLimit?.length > 1) {
    return false;
  }

  if (sum >= MAX_SUM) {
    return false;
  }

  return true;
};
